html, body {
  margin : 0;
  padding: 0;
  height: 100%;
  min-width: 500px;
}

body {
  background: #141E30;
  background: -webkit-linear-gradient(to right, #243B55, #141E30);
  background: linear-gradient(to right, #243B55, #141E30);
  color: white;
}

h1 {
  font-family: 'Pacifico', cursive;
  font-size: 3em;
  color: #ea5455;
  text-shadow: 2px 2px 0px #216583, 5px 4px 0px rgba(0,0,0,0.15);
  margin-bottom: 0;
  -webkit-animation:slide-in-top .5s cubic-bezier(.25,.46,.45,.94) both;
  animation:slide-in-top .5s cubic-bezier(.25,.46,.45,.94) both;
}

h1 span {
  font-size: 0.4em;
  color: #ff894c;
}

#root {
  height: 100%;
}

#welcome {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

#slogan {
  font-family: 'Pacifico', cursive;
  font-size: 1.5em;
  -webkit-animation:fade-in 1.2s cubic-bezier(.39,.575,.565,1.000) both;
  animation:fade-in 1.2s cubic-bezier(.39,.575,.565,1.000) both;
}

#soon {
  padding: 10px 30px;
  font-family: arial;
  text-transform: uppercase;
  background: white;
  color: black;
  margin: 15px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  -webkit-animation:flip-horizontal-bottom .4s cubic-bezier(.455,.03,.515,.955) both;
  animation:flip-horizontal-bottom .4s cubic-bezier(.455,.03,.515,.955) both;
  cursor: pointer;
}

#twitter {
  bottom: 10px;
  position: absolute;
}

#twitter img {
  height: 30px;
}

@-webkit-keyframes flip-horizontal-bottom{0%{-webkit-transform:rotateX(0);transform:rotateX(0)}100%{-webkit-transform:rotateX(-360deg);transform:rotateX(-360deg)}}@keyframes flip-horizontal-bottom{0%{-webkit-transform:rotateX(0);transform:rotateX(0)}100%{-webkit-transform:rotateX(-360deg);transform:rotateX(-360deg)}}
@-webkit-keyframes slide-in-top{0%{-webkit-transform:translateY(-1000px);transform:translateY(-1000px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}@keyframes slide-in-top{0%{-webkit-transform:translateY(-1000px);transform:translateY(-1000px);opacity:0}100%{-webkit-transform:translateY(0);transform:translateY(0);opacity:1}}
@-webkit-keyframes fade-in{0%{opacity:0}100%{opacity:1}}@keyframes fade-in{0%{opacity:0}100%{opacity:1}}